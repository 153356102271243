<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error al obtener los datos de la pregunta
      </h4>
      <div class="alert-body">
        No se encontró ninguna pregunta con este id. Verificar en la
        <b-link
          class="alert-link"
          :to="{ name: 'questions'}"
        >
          Lista de preguntas
        </b-link>
        para buscar otra pregunta.
      </div>
    </b-alert>

    <b-tabs
      v-if="userData"
      pills
    >
      
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Información</span>
        </template>
        <question-edit-tab-information
          class="mt-2 pt-75" 
          :user-data="userData"
          />
      </b-tab>
      
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import questionStoreModule from '../questionStoreModule';
import QuestionEditTabInformation from './QuestionEditTabInformation.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    
    QuestionEditTabInformation,
  },
  setup() {
    const userData = ref(null);

    const USER_APP_STORE_MODULE_NAME = 'app-user';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, questionStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store.dispatch('app-user/fetchQuestion', { id: router.currentRoute.params.id })

    .then(response => {
      if (response.data === 'error') {
        userData.value = undefined;
      } else {
        userData.value = response.data;
      }
    })
    .catch(error => {
      if (error.response.status === 404) {
        userData.value = undefined;
      }
    });

    return {
      userData,
    }
  },
}
</script>