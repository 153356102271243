import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchQuestions(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/questions`, { params: queryParams })
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        fetchQuestion(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/question/${id}/edit`)
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        updateQuestion (ctx, { id, questionData }) {
            return new Promise((resolve, reject) => {
                console.log(questionData);
                axios
                .put(`${process.env.VUE_APP_HOST}/api/dashboard/question/${id}/update`, questionData)
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        deleteQuestion (ctx, { id }){
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/question/${id}/delete`)
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        }
    },
}
