<template>
  <div>


    <!-- Form: Info Form -->
      <b-form>
          <b-row>
              <b-col md="12">
                  <b-form-group
                      label="Código">
                      <b-form-input class="font-weight-bold" v-model="code" disabled readonly></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col md="12">
                  <b-form-group
                      class="mb-0"
                      label="Pregunta">
                      <b-form-textarea
                          v-model="question"
                          placeholder="Ingrese la pregunta e instrucciones ..."
                          rows="5"
                      ></b-form-textarea>
                  </b-form-group>
              </b-col>
              <b-col md="12">
                  <hr>
                  <b-form-group
                      class="mb-0"
                      label="Tipo de pregunta">
                      <b-form-select v-model="selected" :options="options"></b-form-select>
                  </b-form-group>
                  <hr>
              </b-col>
              <b-col md="12" v-if="selected">
                  <b-form-group
                      label="Alternativas:"
                      ref="alternatives"
                      class="question-options">
                      <b-form-radio
                          v-if="selected == '1'"
                          class="b-form-radio"
                          v-for="(alternative, index) in alternatives"
                          :key="index"
                          v-model="selected2"
                          :value="alternative"
                          ref="alt">
                          <b-form-input v-model="alternative.text"></b-form-input>
                          <b-button
                              v-if="index !== 0"
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              @click="removeAlternative(index, 'radio', alternative.id)">
                              <feather-icon icon="Trash2Icon" />
                          </b-button>
                      </b-form-radio>
                      <b-form-checkbox
                          v-if="selected == '2'"
                          class="b-form-radio"
                          v-for="(alternative, index) in alternatives"
                          :key="index"
                          v-model="selected2"
                          :value="alternative"
                          ref="alt">
                          <b-form-input v-model="alternative.text"></b-form-input>
                          <b-button
                              v-if="index !== 0"
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              @click="removeAlternative(index, 'checkbox', alternative.id)">
                              <feather-icon icon="Trash2Icon" />
                          </b-button>
                      </b-form-checkbox>
                  </b-form-group>
                  <hr>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      @click="addAlternative">
                      <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                      />
                      <span>AGREGAR ALTERNATIVA</span>
                  </b-button>
                  <hr>
              </b-col>

          </b-row>
      </b-form>
    
    <!-- Action Buttons -->
    <b-button
      @click="updateInformation"
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      GUARDAR CAMBIOS
    </b-button>
  </div>
</template>

<script>

    import Toast from 'vue-toastification';
    import Vue from 'vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BFormRadio, BFormCheckbox, BForm, BFormTextarea, BFormSelect } from 'bootstrap-vue';
    import vSelect from 'vue-select';
    import store from '@/store';
    import Ripple from 'vue-ripple-directive';

    Vue.use(Toast);

export default {
  components: {
      BButton,
      BMedia,
      BAvatar,
      BRow,
      BCol,
      BFormGroup,
      BFormRadio,
      BFormCheckbox,
      BFormInput,
      BFormTextarea,
      BForm,
      BFormSelect,
      vSelect,
  },
    directives: {
        Ripple,
    },
  props: {
      userData: {
          type: Object,
          required: true,
      },
  },
    data(){
      return{
          code: '',
          question: '',
          selected: null,
          init: true,
          options: [
              { value: null, text: 'Seleccione un tipo de pregunta' },
              { value: 1, text: 'Una respuesta' },
              { value: 2, text: 'Varias respuestas' },
          ],

          deletedQuestion: [],
          selected2: {},
          nextTodoId: 1,
          alternatives: []
      }
    },
    watch: {
        selected(newValue){
            if (newValue && !this.init){
                if (newValue == 1){
                    this.selected2 = {};
                } else {
                    this.selected2 = [];
                }
            }
        }
    },
    mounted (){
        
        this.code = this.userData.code;
        this.question = this.userData.question;
        this.selected = this.userData.questionTypeId;
        setTimeout(() => {
            this.correctAnswers(this.userData.answers);
            this.init = false;
        }, 500);
    },
  methods: {      
      correctAnswers (answers){
          
          this.alternatives = [];
          this.selected2 = [];
          answers.map(item => {
              this.alternatives.push({
                  id: item.id,
                  exists: true,
                  text: item.answer
              });

              if (this.selected == 1){
                  if (item.isCorrect){
                      this.selected2 = {
                          id: item.id,
                          exists: true,
                          text: item.answer
                      };
                  }
              } else {
                  if (item.isCorrect){
                      this.selected2.push({
                          id: item.id,
                          exists: true,
                          text: item.answer
                      });
                  }
              }
          });
      },
      updateInformation () {

          let error = 0;
          let errorE1 = 0;
          let errorG = 0;

          this.alternatives.map(item => {
              if (item.text === ''){
                  error = 1;
              }
          });

          if (this.question === '' || !this.selected || error){
              errorE1 = 1;
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: 'Debe completar toda la información.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                  }
              });

          }

          if (!errorE1 && this.selected == 1){

              if (!this.selected2.id){
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Debe seleccionar una opción correcta.',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                      }
                  });
                  errorG = 1;
              }

          }

          if (!errorE1 && this.selected == 2){
              if (this.selected2.length < 2){
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Debe seleccionar 2 opciones correctas, como mínimo.',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                      }
                  });
                  errorG = 1;
              }
          }

          if (!errorG && !errorE1){

              let updateExistsQuestions = [];
              let newQuestions = [];
              this.alternatives.map(item => {

                  let isCorrect = 0;
                  if (this.selected == 1){
                      if (this.selected2.id == item.id){
                          isCorrect = 1;
                      }
                  } else {
                      let found = this.selected2.find(i => i.id == item.id);
                      if (found){
                          isCorrect = 1;
                      }
                  }

                  if (item.exists){

                      updateExistsQuestions.push({
                          id: item.id,
                          answer: item.text,
                          isCorrect
                      });

                  } else {

                      newQuestions.push({
                          answer: item.text,
                          isCorrect
                      });

                  }
              });

              let questionData = {
                  question: this.question,
                  questionTypeId: this.selected,
                  deletedQuestion: this.deletedQuestion,
                  updateExistsQuestions,
                  newQuestions
              };
              
              store.dispatch('app-user/updateQuestion', { id: this.userData.id, questionData})
                  .then((response) => {

                      this.$toast({
                          component: ToastificationContent,
                          props: {
                              title: response.data.message,
                              icon: 'CheckIcon',
                              variant: 'success'
                          }
                      });

                      this.deletedQuestion = [];
                      this.nextTodoId = 1;
                      this.correctAnswers(response.data.answers);

                  })
                  .catch( (err) => {
                      let message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información del cliente';
                      this.$toast({
                          component: ToastificationContent,
                          props: {
                              title: message,
                              icon: 'AlertTriangleIcon',
                              variant: 'danger'
                          }
                      });
                  });

          }

      },
      addAlternative() {
          this.alternatives.push({
              id: `new-${this.nextTodoId}`,
              exists: false,
              text: ''
          });

          this.nextTodoId += 1;
      },
      removeAlternative(index, type, id) {

          let alternative = this.alternatives.find(u => u.id == id);
          if (alternative && alternative.exists){
              this.deletedQuestion.push(alternative.id);
          }

          this.alternatives.splice(index, 1);

          if (type === 'radio'){

              if (this.selected2.id == id){
                  this.selected2 = {};
              }

          } else {
              this.selected2 = this.selected2.filter(u => u.id !== id);
          }

      }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>

    .custom-control-label .form-control {
        width: 90%;
        display: inline-block;
        margin-right: 1rem;
    }

</style>

<style>

    .question-options .custom-radio .custom-control-label,
    .question-options .custom-checkbox .custom-control-label {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .question-options .custom-radio .custom-control-label::before,
    .question-options .custom-checkbox .custom-control-label::before {
        top: 0.72rem !important;
    }

    .question-options .custom-radio .custom-control-label::after,
    .question-options .custom-checkbox .custom-control-label::after {
        top: 0.72rem !important;
    }

    .question-options .custom-control.custom-radio,
    .question-options .custom-control.custom-checkbox {
        margin-bottom: 1rem;
    }

    .question-options .custom-control.custom-radio:nth-last-child(1),
    .question-options .custom-control.custom-checkbox:nth-last-child(1) {
        margin-bottom: 0;
    }

</style>

